<script lang="ts" setup>
import ButtonSecondary from "~/components/ButtonSecondary.vue";
import ModalBox from "~/components/ModalBox.vue";
import { type PropType, type Ref } from "vue";
import DetailsAndAddToCart from "~/components/product/DetailsAndAddToCart.vue";
import type Product from "~/types/Product"; // Properties

// Properties
const props = defineProps({
    product: {
        type: Object as PropType<Product>,
    },
    showButton: {
        type: Boolean,
        default: false,
    },
});

// Data
const showModalBox: Ref<boolean> = ref(false);

// When modal is open, it takes a second or two for it to be rendered. Check when child component is rendered.
const childMounted = ref(false);
watch(showModalBox, (newVal, oldVal) => {
    // When modal is closed, reset childMounted = false.
    if (newVal) {
        childMounted.value = false;
    }
});
</script>

<template>
    <div class="hidden md:block">
        <ModalBox v-model:showModalBox="showModalBox" width="w-11/12 sm:w-3/4 lg:w-[1000px]">
            <!-- Only show the details when the modal is opened to avoid triggering a "view" of the product-->
            <DetailsAndAddToCart v-if="showModalBox" :product="product" @vue:mounted="childMounted = true" />
            <ButtonSecondary
                v-if="childMounted"
                type="link"
                :href="`/products/${product.c_slug ?? product.slug}`"
                class="mt-8 w-full text-center"
            >
                See Full Product Details
            </ButtonSecondary>
            <div v-else class="text-center">
                <img src="/images/spinnersBlocksWave.svg" alt="Spinner" class="mx-auto w-20" />
                Loading...
            </div>
        </ModalBox>
        <ButtonSecondary v-show="showButton" class="whitespace-nowrap text-sm" py="py-1" @click="showModalBox = true">
            Quick Shop
        </ButtonSecondary>
    </div>
</template>

<style scoped></style>
