<script lang="ts" setup></script>

<template>
    <div class="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5">
        <a href="https://usfcr.com" target="_blank">
            <NuxtImg
                src="https://usfcr.com/assets/img/verified-vendor-seal-2024-sm.png"
                format="webp"
                alt="USFCR Verified Vendor"
                title="US Federal Contractor Registration System for Award Management Verified Vendor Seal"
                class="mx-auto w-[100px]"
            />
        </a>
        <a href="https://www.gachamber.com/" target="_blank">
            <NuxtImg
                src="/images/home/credentials/gachamber.png"
                format="webp"
                alt="Georgia Chamber"
                title="Georgia Chamber of Commerce"
                class="mx-auto"
            />
        </a>
        <a href="https://www.bbb.org" target="_blank">
            <NuxtImg
                src="/images/home/credentials/bbb.png"
                format="webp"
                alt="BBB"
                title="Better Business Bureau"
                class="mx-auto"
            />
        </a>

        <a href="https://publishers.org/" target="_blank">
            <NuxtImg
                src="/images/home/credentials/aap.png"
                format="webp"
                alt="AAP"
                title="Association of American Publishers"
                class="mx-auto"
            />
        </a>

        <a href="https://www.wbenc.org" target="_blank">
            <NuxtImg
                src="/images/home/credentials/wbenc.png"
                format="webp"
                alt="WBENC"
                title="Women's Business Enterprise national Council"
                class="mx-auto"
            />
        </a>
    </div>
</template>

<style scoped></style>
