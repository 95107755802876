<script lang="ts" setup>
// Properties
import QuickShop from "~/components/product/QuickShop.vue";
import LookInside from "~/components/product/LookInside.vue";
import type Product from "~/types/Product";
import { type PropType } from "vue";

const props = defineProps({
    product: {
        type: Object as PropType<Product>,
    },
    showPrice: {
        type: Boolean,
        default: true,
    },
    listItemsAsGrid: {
        type: Boolean,
        default: true,
    },
});

// Data
const showPopupButtons = ref(false);
</script>

<template>
    <!-- Grid format -->
    <div
        v-if="listItemsAsGrid"
        class="flex py-4 even:bg-gray-50 sm:block sm:py-0 sm:even:bg-white"
        @mouseover="showPopupButtons = true"
        @mouseleave="showPopupButtons = false"
    >
        <div class="relative w-2/6 flex-none sm:w-full">
            <NuxtLink :to="`/products/${product?.slug ?? product?.c_slug}`">
                <img
                    :src="product?.photo_lg"
                    :srcset="`${product?.photo_lg} 1x, ${product?.photo_lg_2x} 2x`"
                    width="205"
                    height="265"
                    :alt="'Cover Image ' + product?.title"
                    class="mx-auto mb-3 max-h-60 border border-gray-300 shadow-md shadow-gray-400 sm:max-h-[280px]"
                />
            </NuxtLink>
            <div class="absolute bottom-2 w-full text-center">
                <!-- Lazy load components -->
                <LookInside v-if="product" v-model:show-button="showPopupButtons" :product="product" class="z-0" />
                <QuickShop v-if="product" v-model:show-button="showPopupButtons" :product="product" class="mt-2" />
            </div>
        </div>
        <div class="flex-1 pl-4 md:pl-0">
            <div class="cut-text text-left font-bold leading-tight">
                <NuxtLink :to="`/products/${product?.slug ?? product?.c_slug}`">
                    {{ product.title }}
                </NuxtLink>
            </div>
            <div v-if="showPrice && product?.price_bulk" class="mt-6 text-left md:mt-3">
                <div class="text-sm text-gray-500">Starting at</div>
                <div class="text-xl font-semibold">${{ product.price_bulk.toFixed(2) }}</div>
            </div>
        </div>
    </div>
    <!-- List format -->
    <div v-else class="flex py-4 even:bg-gray-50">
        <div
            class="relative w-32 flex-none"
            @mouseover="showPopupButtons = true"
            @mouseleave="showPopupButtons = false"
        >
            <NuxtLink :to="`/products/${product?.slug ?? product?.c_slug}`">
                <img
                    :src="product?.photo"
                    :srcset="`${product?.photo} 1x, ${product?.photo_2x} 2x`"
                    width="128"
                    height="164"
                    :alt="'Cover Image ' + product?.title"
                    class="mx-auto mb-3 max-h-60 border border-gray-300 shadow-md shadow-gray-400 sm:max-h-[280px]"
                />
            </NuxtLink>
            <div class="absolute bottom-2 w-full text-center">
                <!-- Lazy load components -->
                <LookInside v-if="product" v-model:show-button="showPopupButtons" :product="product" />
                <QuickShop v-if="product" v-model:show-button="showPopupButtons" :product="product" class="mt-2" />
            </div>
        </div>
        <div class="flex-1 pl-4 md:pl-8">
            <div class="text-left leading-tight">
                <NuxtLink :to="`/products/${product?.slug ?? product?.c_slug}`" class="font-bold">
                    {{ product.title }}
                </NuxtLink>
            </div>
            <div v-if="showPrice && product?.price_bulk" class="mt-6 text-left md:mt-3">
                <div class="text-sm text-gray-500">Starting at</div>
                <div class="text-xl font-extrabold text-black">${{ product?.price_bulk.toFixed(2) }}</div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.cut-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.5em;
}
</style>
